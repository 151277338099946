var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: _vm.idTable + "_container " } },
    [
      _c(
        "div",
        {
          staticClass: "table_scroll_duplicate",
          attrs: { id: _vm.idTable + "_scrollDuplicate" },
        },
        [_c("div", { attrs: { id: _vm.idTable + "_dummy" } })]
      ),
      _c(
        "v-data-table",
        {
          attrs: {
            id: _vm.idTable,
            loading: _vm.loadingTable,
            headers: _vm.headers,
            items: _vm.items,
            "item-class": _vm.rowClasses,
            "items-per-page": 50,
            "footer-props": {
              disablePagination: _vm.items.length === 0 || _vm.loadingTable,
              itemsPerPageOptions:
                _vm.totalItems < 50
                  ? [
                      { value: 50, text: "50" },
                      { value: 100, text: "100", disabled: true },
                      { value: 250, text: "250", disabled: true },
                    ]
                  : _vm.totalItems < 100
                  ? [
                      { value: 50, text: "50" },
                      { value: 100, text: "100" },
                      { value: 250, text: "250", disabled: true },
                    ]
                  : [
                      { value: 50, text: "50" },
                      { value: 100, text: "100" },
                      { value: 250, text: "250" },
                    ],
              disableItemsPerPage: _vm.items.length === 0 || _vm.loadingTable,
              itemsPerPageText: `${_vm.tableTitle} por página: `,
              expandIcon: "mdi-dots-horizontal",
            },
            "no-data-text": `Não há registros de ${_vm.tableTitle.toLowerCase()} a serem exibidos.`,
          },
          on: { "current-items": _vm.onTableInput },
          scopedSlots: _vm._u([
            {
              key: "footer.page-text",
              fn: function (items) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(_vm.loadingTable ? 0 : items.pageStart) +
                      " - " +
                      _vm._s(_vm.loadingTable ? 0 : items.pageStop) +
                      " de " +
                      _vm._s(_vm.loadingTable ? 0 : items.itemsLength) +
                      " " +
                      _vm._s(_vm.tableTitle.toLowerCase()) +
                      " "
                  ),
                ]
              },
            },
            {
              key: "no-data",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex justify-center",
                      style: _vm.getTableWidth,
                      attrs: { id: "noDataText" },
                    },
                    [
                      _c("p", [
                        _vm._v(
                          "Não há registros de " +
                            _vm._s(_vm.tableTitle.toLowerCase()) +
                            " a serem exibidos."
                        ),
                      ]),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "item.id_column",
              fn: function ({ item }) {
                return [_vm._v(" " + _vm._s(item.id_column) + " ")]
              },
            },
            {
              key: "item.compatibility",
              fn: function ({ item }) {
                return [
                  _c("div", { staticClass: "d-flex flex-row align-center" }, [
                    _c(
                      "div",
                      { class: _vm.compatibilityTextClass(item.compatibility) },
                      [_vm._v(" " + _vm._s(item.compatibility) + " ")]
                    ),
                    _vm.isTotalCompatibility(item.compatibility)
                      ? _c(
                          "div",
                          { staticClass: "ml-4" },
                          [
                            _c("IconTooltip", {
                              attrs: {
                                icon: "warning",
                                iconClass:
                                  "material-symbols-outlined cbo-search-total-compatibility-icon",
                                color: "#807726",
                                tooltipText: _vm.txt.totalCompatibilityTooltip,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              },
            },
            {
              key: "item.occupation",
              fn: function ({ item }) {
                return [
                  _vm.checkLength(item.occupation)
                    ? _c(
                        "AGTooltip",
                        { attrs: { "tooltip-text": item.occupation } },
                        [
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-no-wrap text-truncate cbo-search-table-width",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.truncateText(item.occupation)) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                        ],
                        2
                      )
                    : _c("div", { staticClass: "cbo-search-table-width" }, [
                        _vm._v(" " + _vm._s(item.occupation) + " "),
                      ]),
                ]
              },
            },
            {
              key: "item.education",
              fn: function ({ item }) {
                return [
                  _vm.checkLength(item.education)
                    ? _c(
                        "AGTooltip",
                        { attrs: { "tooltip-text": item.education } },
                        [
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-no-wrap text-truncate cbo-search-table-width",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.truncateText(item.education)) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                        ],
                        2
                      )
                    : _c("div", { staticClass: "cbo-search-table-width" }, [
                        _vm._v(" " + _vm._s(item.education) + " "),
                      ]),
                ]
              },
            },
            {
              key: "item.professionalRegulatoryBody",
              fn: function ({ item }) {
                return [
                  _vm.checkLength(item.professionalRegulatoryBody)
                    ? _c(
                        "AGTooltip",
                        {
                          attrs: {
                            "tooltip-text": item.professionalRegulatoryBody,
                          },
                        },
                        [
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-no-wrap text-truncate cbo-search-table-width",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.truncateText(
                                        item.professionalRegulatoryBody
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                        ],
                        2
                      )
                    : _c("div", { staticClass: "cbo-search-table-width" }, [
                        _vm._v(
                          " " + _vm._s(item.professionalRegulatoryBody) + " "
                        ),
                      ]),
                ]
              },
            },
          ]),
        },
        [
          _vm.loadingTable
            ? _c("template", { slot: "body" }, [
                _c(
                  "tr",
                  _vm._l(_vm.headers, function (loadItem) {
                    return _c(
                      "td",
                      [
                        _c("v-skeleton-loader", {
                          staticClass: "loaderCss",
                          attrs: { "max-width": loadItem.width },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div :id="idTable + '_container '">
    <div :id="idTable + '_scrollDuplicate'" class="table_scroll_duplicate">
      <div :id="idTable + '_dummy'"></div>
    </div>
    <v-data-table :id="idTable" :loading="loadingTable" :headers="headers" :items="items" :item-class="rowClasses" :items-per-page="50" :footer-props="{
    disablePagination: items.length === 0 || loadingTable,
    itemsPerPageOptions:
      totalItems < 50
        ? [
          { value: 50, text: '50' },
          { value: 100, text: '100', disabled: true },
          { value: 250, text: '250', disabled: true },
        ]
        : totalItems < 100
          ? [
            { value: 50, text: '50' },
            { value: 100, text: '100' },
            { value: 250, text: '250', disabled: true },
          ]
          : [
            { value: 50, text: '50' },
            { value: 100, text: '100' },
            { value: 250, text: '250' },
          ],
    disableItemsPerPage: items.length === 0 || loadingTable,
    itemsPerPageText: `${tableTitle} por página: `,
    expandIcon: 'mdi-dots-horizontal',
  }" :no-data-text="`Não há registros de ${tableTitle.toLowerCase()} a serem exibidos.`"
      @current-items="onTableInput">
      <template v-slot:footer.page-text="items">
        {{ loadingTable ? 0 : items.pageStart }} - {{ loadingTable ? 0 : items.pageStop }} de
        {{ loadingTable ? 0 : items.itemsLength }} {{ tableTitle.toLowerCase() }}
      </template>
      <template slot="body" v-if="loadingTable">
        <tr>
          <td v-for="loadItem in headers">
            <v-skeleton-loader :max-width="loadItem.width" class="loaderCss"></v-skeleton-loader>
          </td>
        </tr>
      </template>

      <template v-slot:no-data>
        <div id="noDataText" class="d-flex justify-center" :style="getTableWidth">
          <p>Não há registros de {{ tableTitle.toLowerCase() }} a serem exibidos.</p>
        </div>
      </template>

      <template v-slot:item.id_column="{ item }">
        {{ item.id_column }}
      </template>

      <template v-slot:item.compatibility="{ item }">
        
        <div class="d-flex flex-row align-center">
          <div :class="compatibilityTextClass(item.compatibility)">
            {{ item.compatibility }}
          </div>
          <div class="ml-4" v-if="isTotalCompatibility(item.compatibility)">
            <IconTooltip icon="warning" iconClass="material-symbols-outlined cbo-search-total-compatibility-icon" color="#807726"
              :tooltipText="txt.totalCompatibilityTooltip"></IconTooltip>
          </div>
        </div>
      </template>

      <template v-slot:item.occupation="{ item }">
        <AGTooltip v-if="checkLength(item.occupation)" :tooltip-text="item.occupation">
          <template slot:tooltipArea>
            <div class="text-no-wrap text-truncate cbo-search-table-width">
              {{ truncateText(item.occupation) }}
            </div>
          </template>
        </AGTooltip>
        <div v-else class="cbo-search-table-width">
          {{ item.occupation }}
        </div>
      </template>

      <template v-slot:item.education="{ item }">
        <AGTooltip v-if="checkLength(item.education)" :tooltip-text="item.education">
          <template slot:tooltipArea>
            <div class="text-no-wrap text-truncate cbo-search-table-width">
              {{ truncateText(item.education) }}
            </div>
          </template>
        </AGTooltip>
        <div v-else class="cbo-search-table-width">
          {{ item.education }}
        </div>
      </template>

      <template v-slot:item.professionalRegulatoryBody="{ item }">
        <AGTooltip v-if="checkLength(item.professionalRegulatoryBody)" :tooltip-text="item.professionalRegulatoryBody">
          <template slot:tooltipArea>
            <div class="text-no-wrap text-truncate cbo-search-table-width">
              {{ truncateText(item.professionalRegulatoryBody) }}
            </div>
          </template>
        </AGTooltip>
        <div v-else class="cbo-search-table-width">
          {{ item.professionalRegulatoryBody }}
        </div>
      </template>

    </v-data-table>
  </div>
</template>

<script>
import { uniqueId } from 'lodash';
import TableMixin from "@/mixins/Table.js";
import { checkLength, truncateText } from "@/utils/text.js";

export default {
  name: 'CboSearchTable',

  components: {
    AGTooltip: () => import('../../../commons/AGTooltip.vue'),
    IconTooltip: () => import('../../../commons/IconTooltip.vue')
  },
  mixins: [TableMixin],

  props: {
    idTable: {
      type: String,
      default: uniqueId('table_'),
    },
    loadingTable: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    totalItems: {
      type: Number,
      required: false,
    },
    footerProps: {
      type: Object,
      default: () => {
        return {};
      },
    },
    tableTitle: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      txt: {
        totalCompatibilityTooltip: "Atenção! O texto é uma cópia da descrição sumária prevista na Classificação Brasileira de Ocupações. Recomendamos aplicar uma descrição que seja fidedigna com as atividades efetivamente realizadas pelo trabalhador(a)."
      }
    }
  },

  methods: {
    truncateText,
    checkLength,
    isTotalCompatibility(compatibiliy) {
      return compatibiliy === '100%';
    },
    rowClasses(item) {
      return this.isTotalCompatibility(item.compatibility) ? 'cbo-search-total-compatibility-row' : '';
    },
    compatibilityTextClass(compatibility) {
        return this.isTotalCompatibility(compatibility) ? 'cbo-search-total-compatibility-text' : '';
    },
  },
  
};
</script>

<style lang="scss" scoped>
@import './src/design/variables.scss';
@import './src/design/components/table.scss';
@import './src/design/components/tooltip.scss';

::v-deep {
  .cbo-search-total-compatibility-row {
    background-color: $feedback-color-attention-light !important;
  }
  .cbo-search-total-compatibility-text {
    color: $feedback-color-attention-dark;
    line-height: $line-height-lg;
  }
  .cbo-search-total-compatibility-icon {
    line-height: 25px;
  }
}

.cbo-search-table-width {
  min-width: 300px;
}

</style>